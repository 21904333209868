import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Card, Col, Container, Row, Spinner, Modal, Button } from "react-bootstrap"; // Import Modal and Button
import $ from 'jquery';
import { useDispatch } from "react-redux";
import { useParams, useHistory } from 'react-router-dom';

const WalletView = ({ user }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState([]);
    const [walletDetails, setWalletDetails] = useState([]);
    const [walletCurrencyDetails, setWalletCurrencyDetails] = useState([]);
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const [selectedImage, setSelectedImage] = useState(''); // State to hold the selected image URL
    const { userId } = useParams();

    useEffect(() => {
        const token = user.user.user.token;
        getTransactionsData(token);
        getWalletData(token);
    }, [user]);

    useEffect(() => {
        // Initialize the DataTable when transactions change
        if (!loading && transactions.length > 0) {
            if (!$.fn.DataTable.isDataTable(dataTableRef.current)) {
                // If not initialized, initialize the DataTable
                $(dataTableRef.current).DataTable({
                    data: transactions,
                    columns: [
                        { data: 'id', title: 'ID' },
                        { data: 'requested_amount', title: 'Requested Amount' },
                        { data: 'transaction_amount', title: 'Approved Amount' },
                        { data: 'transaction_status', title: 'Status' },
                        { data: 'payment_proof', title: 'Payment Proof' },
                    ], columnDefs: [
                        // Custom rendering for the 'status' column
                        {
                            targets: 3, // Index of the 'status' column
                            render: function (data, type, row) {
                                // Call your custom functions for color and icon based on 'status'
                                const color = getStatusColor(data);
                                const statusName = getStatusName(data);

                                // Return HTML with custom styling
                                return `<p className= "mb-0 ${color} font-weight-bold d-flex justify-content-start align-items-center">
                                <small><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                                    <circle cx="12" cy="12" r="8" fill=${color}></circle></svg>
                                </small>${statusName}
                            </p>`;
                            },

                        },
                        {
                            targets: 4, // Index of the 'payment_proof' column
                            render: function (data, type, row) {
                                // Create a view icon instead of directly displaying the image
                                var baseUrl = process.env.REACT_APP_BACKEND_URL;
                                var paymentProof = `${data}`;

                                // Return an icon with an onClick event to show the modal
                                return `<i class="fas fa-eye" style="cursor:pointer; color: #007bff;" onclick="showImageModal('${paymentProof}')"></i>`;
                            },
                        },
                    ],
                    order: [[0, 'desc']],
                });

                // Define the showImageModal function on the window object
                window.showImageModal = showImageModal; // Attach the function to the global window object
            }
        }
    }, [transactions, loading]);

    const dataTableRef = useRef(null);

    const showImageModal = (imageUrl) => {
        setSelectedImage(imageUrl);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedImage('');
    };

    const getTransactionsData = (token) => {
        axios
            .get(process.env.REACT_APP_API_URL + '/resellers/' + userId + '/transactions', {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            .then((response) => {
                setTransactions(response.data.transactionData);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching transactions:', error);
                setLoading(false);
            });
    };

    const getWalletData = (token) => {
        axios
            .get(process.env.REACT_APP_API_URL + '/resellers/wallet/' + userId, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            .then((response) => {
                setWalletDetails(response.data.walletDetails);
                setWalletCurrencyDetails(response.data.walletDetails.currency);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching wallet details:', error);
                setLoading(false);
            });
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'pending':
                return '#3498db'; // Blue
            case 'completed':
                return '#3cb72c'; // Green
            case 'declined':
                return '#e74c3c'; // Red
            case 'refunded':
                return '#f39c12'; // Orange
            case 'partially refunded':
                return '#e67e22'; // Darker Orange
            default:
                return '#000000'; // Black (Fallback color)
        }
    };

    const getStatusName = (status) => {
        let statusText;
        switch (status) {
            case 'pending':
                statusText = 'Pending';
                break;
            case 'completed':
                statusText = 'Completed';
                break;
            case 'declined':
                statusText = 'Declined';
                break;
            case 'refunded':
                statusText = 'Refunded';
                break;
            case 'partially refunded':
                statusText = 'Partially Refunded';
                break;
            default:
                statusText = 'Unknown Status';
        }
        return statusText;
    };
    const isNotImage = (fileName) => {
        if (!fileName) return true; // If no file name is provided, treat it as not an image

        // List of valid image extensions
        const validImageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "svg"];

        // Extract the file extension
        const fileExtension = fileName.split(".").pop().toLowerCase();

        // Check if the file extension is in the valid list
        return !validImageExtensions.includes(fileExtension);
    };

    const goBack = () => {
        history.goBack();
    };

    return (
        <section className="walletView">
            <Container fluid>
                <Row>
                    <Col md={6}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Balance Details</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row className="align-items-center">
                                    <Col md={12} className="text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ width: '90px', height: '90px' }} className="float-center">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M3 5a2 2 0 012-2h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5zm2 0a1 1 0 011-1h12a1 1 0 011 1v2H5V5zm0 4h14M5 13h14m-7 4v-4"></path>
                                        </svg>
                                    </Col>
                                </Row>
                                <Row className="align-items-center">
                                    <Col md={12} className="text-center">
                                        <p><strong style={{ fontSize: '1.5rem' }}>{walletCurrencyDetails.symbol}{walletDetails.balance}</strong></p>
                                        <p style={{ fontSize: '1.2rem' }}>Available Balance</p>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                {/* Add your content here */}
                            </div>
                            <Button onClick={goBack} className="loginBtn">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                Back
                            </Button>
                        </div>
                    </Col>
                    <Col lg="12">
                        <Card className="card-block card-stretch">
                            <Card.Body className="p-0">
                                <div className="d-flex justify-content-between align-items-center p-3">
                                    <h5 className="font-weight-bold">Transactions List</h5>
                                </div>
                                <div className="table-responsive">
                                    {loading ? (
                                        <div className="text-center mb-5">
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        </div>
                                    ) : transactions.length > 0 ? (
                                        <table ref={dataTableRef} className="table data-table mb-0">
                                        </table>
                                    ) : (
                                        <div className="text-center mb-5">
                                            <p>No transactions available.</p>
                                        </div>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {/* Modal for displaying the image */}
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header>
                    <Modal.Title>Payment Proof</Modal.Title>
                    <span style={{ cursor: 'pointer', marginLeft: 'auto' }} onClick={handleCloseModal}>
                        {/* Example SVG Icon */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </span>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {!isNotImage(selectedImage) ? (
                        <img
                            src={`https://api.2gconnect.com/payment_proofs/${userId}/${selectedImage}`}
                            alt="Payment Proof"
                            className="img-fluid"
                        />
                    ) : (
                        <a
                            href={`https://api.2gconnect.com/payment_proofs/${userId}/${selectedImage}`}
                            download
                            className="btn btn-primary"
                            style={{ textDecoration: "none", color: "white" }}
                        >
                            Download File
                        </a>
                    )}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
};

export default WalletView;
