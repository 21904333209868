import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Container, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../store/actions';
import $ from 'jquery';
import Swal from 'sweetalert2';
import DownloadExcel from './DownloadExcel';
// import "datatables.net-fixedcolumns";

const Commissions = ({ user }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [bulkShow, setBulkShow] = useState(false);
    const [filterModalShow, setFilterModalShow] = useState(false);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [operators, setOperators] = useState([]);
    const [operatorIds, setOperatorIds] = useState([]);
    const [margin, setMargin] = useState(null);
    const [fx_rate, setFxRate] = useState(null);
    const [country, setCountry] = useState(null);
    const [defaultVendor, setDefaultVendor] = useState(null);
    const [status, setStatus] = useState(true);
    const [countries, setCountries] = useState([]);
    const [downloadData, setDownloadData] = useState([]);
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);
    const [uploadModalShow, setUploadModalShow] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const dataTableRef = useRef(null);
    const [filterData, setFilterData] = useState({
        id: '',
        gatewayId: '',
        country: '',
        originalName: '',
        ourName: '',
        operatorId: '',
        skuCode: '',
        productId: '',
        status: '',
        limit: 50
    });

    useEffect(() => {
        getCountries();
        setIsLoading(true);
        const token = user.user.user.token;

        const headers = {
            Authorization: `Bearer ${token}`,
        }

        axios.post(process.env.REACT_APP_API_URL + '/get-operators-for-commission', filterData, { headers })
            .then((response) => {
                if (response.status) {
                    setOperators(response.data.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.error('Error fetching users:', error);

                if (error.response.status === 401) {
                    dispatch(logoutUser());
                }

                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        // Initialize the DataTable when categories change
        if (!isLoading && operators.length > 0) {
            if (!$.fn.DataTable.isDataTable(dataTableRef.current)) {
                // If not initialized, initialize the DataTable
                $(dataTableRef.current).DataTable({
                    order: [[0, 'asc']],
                    scrollX: true,
                    fixedColumns: {
                        leftColumns: 2
                    }
                });
            }

            $(dataTableRef.current).on('click', '.status-button', function () {
                let newData = [];
                newData.push($(this).data('operator-id'));
                setOperatorIds([...newData]);
                setMargin($(this).data('margin'));
                setFxRate($(this).data('fx-rate'));
                setCountry($(this).data('country'));
                setDefaultVendor($(this).data('default-vendor'));
                setStatus($(this).data('status'));
                openModal();
            });

            const operatorsData = [];
            operators.map((operator, index) => {
                operatorsData.push({
                    'Id': operator?.id,
                    'Vendor': operator?.GatewayName,
                    'Default Vendor': operator?.DefaultVendorName,
                    'Country': operator?.country,
                    'Name': operator?.name,
                    'Operator Id': operator?.operator_id,
                    'Sku Code': operator?.sku_code,
                    'Product Id': operator?.product_id,
                    'Fx Rates': operator?.fx_rates,
                    'Margin': operator?.margin,
                    'Status': operator?.status ? 'Active' : 'Deactive',
                });
            });
            setDownloadData(operatorsData);
        }
    }, [operators, isLoading]);

    const getCountries = () => {
        const token = user.user.user.token;

        const headers = {
            Authorization: `Bearer ${token}`,
        }

        axios.get(process.env.REACT_APP_API_URL + '/get-countries-for-operators-filter', { headers })
            .then((res) => {
                if (res.data.status) {
                    setCountries(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err, "err");
            })
    }

    const onFilterChangeHandler = (e) => {
        if (e.target.name === 'id') {
            setFilterData(prevState => ({
                ...prevState,
                id: e.target.value
            }));
        } else if (e.target.name === 'gatewayId') {
            setFilterData(prevState => ({
                ...prevState,
                gatewayId: e.target.value
            }));
        } else if (e.target.name === 'country') {
            setFilterData(prevState => ({
                ...prevState,
                country: e.target.value
            }));
        } else if (e.target.name === 'originalName') {
            setFilterData(prevState => ({
                ...prevState,
                originalName: e.target.value
            }));
        } else if (e.target.name === 'ourName') {
            setFilterData(prevState => ({
                ...prevState,
                ourName: e.target.value
            }));
        } else if (e.target.name === 'operatorId') {
            setFilterData(prevState => ({
                ...prevState,
                operatorId: e.target.value
            }));
        } else if (e.target.name === 'skuCode') {
            setFilterData(prevState => ({
                ...prevState,
                skuCode: e.target.value
            }));
        } else if (e.target.name === 'productId') {
            setFilterData(prevState => ({
                ...prevState,
                productId: e.target.value
            }));
        } else if (e.target.name === 'status') {
            setFilterData(prevState => ({
                ...prevState,
                status: e.target.value
            }));
        } else if (e.target.name === 'limit') {
            setFilterData(prevState => ({
                ...prevState,
                limit: e.target.value
            }));
        }
    }

    const onFilterSubmitHandler = (e) => {
        e.preventDefault();
        handleFilterModalClose();

        if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
            $(dataTableRef.current).DataTable().destroy(); // Destroy existing DataTable
        }

        setIsLoading(true);
        setOperators([]);

        const token = user.user.user.token;

        const headers = {
            Authorization: `Bearer ${token}`,
        }

        axios.post(process.env.REACT_APP_API_URL + '/get-operators-for-commission', filterData, { headers })
            .then((response) => {
                setOperators(response.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching users:', error);

                if (error.response.status === 401) {
                    dispatch(logoutUser());
                }

                setIsLoading(false);
            });
    }

    const openModal = () => {
        setShow(true);
    }

    const openBulkModal = () => {
        setBulkShow(true);
    }

    const handleClose = () => {
        setMargin(null);
        setFxRate(null);
        setShow(false);
    }

    const handleBulkClose = () => {
        setMargin(null);
        setFxRate(null);
        setBulkShow(false);
    }

    const handleFilterModalClose = () => {
        setFilterModalShow(false);
        // setFilterData({
        //     id: '',
        //     gatewayId: '',
        //     country: '',
        //     originalName: '',
        //     ourName: '',
        //     operatorId: '',
        //     skuCode: '',
        //     productId: '',
        //     status: '',
        //     limit: 50
        // });
    }

    const onSubmitHandler = () => {
        setIsSubmiting(true);
        const token = user.user.user.token;

        const headers = {
            Authorization: `Bearer ${token}`,
        }

        const data = {
            operatorIds: operatorIds,
            margin: margin,
            fx_rate: fx_rate,
            status: status,
            default_vendor: defaultVendor
        };

        axios.post(process.env.REACT_APP_API_URL + '/update-operators-for-commission', { data: data }, { headers })
            .then((response) => {
                if (response.data.status) {
                    Swal.fire('Success!', response.data.message, 'success')
                        .then(function () {
                            if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
                                $(dataTableRef.current).DataTable().destroy(); // Destroy existing DataTable
                            }
                            handleClose();
                            handleBulkClose();
                            // onFilterSubmit();
                            setOperatorIds([]);
                            setMargin(null);
                            setStatus(true);
                            setIsLoading(true);

                            axios.post(process.env.REACT_APP_API_URL + '/get-operators-for-commission', filterData, { headers })
                                .then((response) => {
                                    if (response.status) {
                                        setOperators(response.data.data);
                                        setIsLoading(false);
                                    } else {
                                        setIsLoading(false);
                                    }
                                })
                                .catch((error) => {
                                    console.error('Error fetching users:', error);

                                    if (error.response.status === 401) {
                                        dispatch(logoutUser());
                                    }

                                    setIsLoading(false);
                                });
                        });
                    setIsSubmiting(false);
                } else {
                    Swal.fire('Ooops!', response.data.message, 'error');
                    setIsSubmiting(false);
                }
            })
            .catch((error) => {
                console.error('Error fetching users:', error);

                if (error.response.status === 401) {
                    dispatch(logoutUser());
                }

                setIsSubmiting(false);
                Swal.fire('Ooops!', error.response.data.message, 'error');
            });
    }

    const onMarginChangeHandler = (e) => {
        setMargin(e.target.value);
    }

    const onFxRateChangeHandler = (e) => {
        setFxRate(e.target.value);
    }

    const onDefaultVendorChangeHandler = (e) => {
        console.log(e.target.value, 'value');
        setDefaultVendor(e.target.value);
    }

    const onStatusChangeHandler = (e) => {
        setStatus(e.target.checked);
    }

    const onSingleSelect = (e) => {
        if (e.target.checked) {
            setOperatorIds([...operatorIds, parseInt(e.target.dataset.id)]);
        } else {
            const newItems = operatorIds.filter((_, i) => parseInt(_) !== parseInt(e.target.dataset.id));
            setOperatorIds(newItems);
        }
    }

    const onBulkEdit = () => {
        openBulkModal();
    }

    const onSelectAll = (e) => {
        console.log();
        if (e.target.checked) {
            const ids = operators.map(o => parseInt(o.id));
            setOperatorIds(ids);
        } else {
            setOperatorIds([]);
        }
    }

    const openFilterModal = () => {
        setFilterModalShow(true);
    }

    const openUploadModal = () => {
        setUploadModalShow(true);
    }

    const handleCloseUploadModal = () => {
        setUploadModalShow(false);
        setError(null);
        setFile(null);
    }

    const changefileHandler = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setError(null);
            setFile(selectedFile);
        }
    }

    const fileUploadHandler = (e) => {
        e.preventDefault();

        const validate = checkValidation();

        if (validate) {
            setIsUploading(true);
            const token = user.user.user.token;

            const headers = {
                Authorization: `Bearer ${token}`,
            }

            const formData = new FormData();
            formData.append('uploadedFile', file);

            axios.post(process.env.REACT_APP_API_URL + '/upload-operators-for-edit', formData, { headers })
                .then((res) => {
                    setIsUploading(false);
                    if (res.data.status) {
                        setOperators(res.data.data);
                        setFile(null);
                        setUploadModalShow(false);
                        Swal.fire('Success!', res.data.message, 'success');
                    } else {
                        Swal.fire('Ooops!', res.data.message, 'error');
                    }
                })
                .catch((err) => {
                    console.log(err);

                    if (err.response.status === 401) {
                        dispatch(logoutUser());
                    }

                    setIsUploading(false);
                    Swal.fire('Ooops!', err.response.data.message, 'error');
                })
        }
    }

    const checkValidation = () => {
        if (file === null) {
            setError('Please upload a file first.');
            return false;
        } else {
            return true;
        }
    }

    const handleDrop = (event) => {
        event.preventDefault();
        const selectedFile = event.dataTransfer.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setError(null); // Reset error if file is selected
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault(); // Prevent default to allow drop
    };

    return (
        <section className="resellerProfile">
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Row>
                            <Col lg="12">
                                <Card className="card-block card-stretch">
                                    <Card.Body className="p-0">
                                        <div className="d-flex justify-content-between align-items-center p-3">
                                            <h5 className="font-weight-bold">Operators List</h5>
                                            <div>
                                                {operatorIds?.length > 0 ?
                                                    <button className="btn btn-primary loginBtn mr-2" onClick={onBulkEdit}>Bulk Edit</button>
                                                    : null
                                                }
                                                <button className="btn btn-primary loginBtn mr-2" onClick={openUploadModal}>
                                                    Upload
                                                </button>
                                                <DownloadExcel data={downloadData} fileName={'operators'} />
                                                <OverlayTrigger placement="top" overlay={<Tooltip>Filter</Tooltip>}>
                                                    <button className="btn btn-primary loginBtn ml-2" onClick={openFilterModal}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
                                                        </svg>
                                                    </button>
                                                </OverlayTrigger>
                                            </div>
                                        </div>

                                        <div className="table-responsive">
                                            {isLoading ? (
                                                <div className="text-center mb-5">
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (
                                                <table ref={dataTableRef} className="table display nowrap" style={{ width: '100%' }}>
                                                    <thead className="table-color-heading text-left">
                                                        <tr className="">
                                                            <th scope="col"><input type="checkbox" onChange={onSelectAll} style={{ cursor: 'pointer' }} /></th>
                                                            <th scope="col">Id</th>
                                                            <th scope="col">Vendor</th>
                                                            <th scope="col">Default Vendor</th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Country</th>
                                                            <th scope="col">Country Code</th>
                                                            <th scope="col">Operator Id</th>
                                                            <th scope="col">Sku Code</th>
                                                            <th scope="col">Product Id</th>
                                                            <th scope="col">Amount Type</th>
                                                            <th scope="col">Amount</th>
                                                            <th scope="col">Fx Rates</th>
                                                            <th scope="col">Margin</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {operators.map((operator, index) => {
                                                            return (
                                                                <tr key={index} className="white-space-no-wrap">
                                                                    <td><input type="checkbox" data-id={operator.id} onChange={onSingleSelect} checked={operatorIds.includes(parseInt(operator.id))} style={{ cursor: 'pointer' }} /></td>
                                                                    <td>{operator.id}</td>
                                                                    <td>{operator.GatewayName}</td>
                                                                    <td>{operator.DefaultVendorName}</td>
                                                                    <td className="">
                                                                        <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                                            <div className="data-content">
                                                                                <div>
                                                                                    <span className="font-weight-bold">{operator.name}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{operator.country}</td>
                                                                    <td>{operator.country_code == null ? 'N/A' : operator.country_code}</td>
                                                                    <td>{operator.operator_id ? operator.operator_id : 'N/A'}</td>
                                                                    <td>{operator.sku_code ? operator.sku_code : 'N/A'}</td>
                                                                    <td>{operator.product_id ? operator.product_id : 'N/A'}</td>
                                                                    <td>{operator.amount_type ? operator.amount_type : 'N/A'}</td>
                                                                    <td>{operator.rates ? operator.rates : 'N/A'}</td>
                                                                    <td>{operator.fx_rates ? operator.fx_rates : 'N/A'}</td>
                                                                    {/* <td>{operator.fx_rates ? parseFloat(operator.fx_rates.toFixed(2)) : 'N/A'}</td> */}
                                                                    <td>{operator.margin ? operator.margin + '%' : 0}</td>
                                                                    <td>{parseInt(operator.status) ? 'Active' : 'Deactive'}</td>
                                                                    <td>
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>Edit Operator</Tooltip>}>
                                                                            <button className="text-info status-button" data-operator-id={operator.id} data-country={operator.country} data-margin={operator.margin ? operator.margin : 0} data-fx-rate={operator.fx_rates ? operator.fx_rates : 0} data-status={operator.status ? true : false} data-default-vendor={operator.default_vendor}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                                                                </svg>
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            {/* Edit Operator Modal */}
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title as="h5">Edit Operator</Modal.Title>
                    <button className="badge bg-danger modal-close-button" onClick={handleClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="margin">Margin (in %)</Form.Label>
                            <Form.Control type="number" id="margin" onChange={onMarginChangeHandler} placeholder='Enter margin' value={margin} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="fx_rates">FX Rate</Form.Label>
                            <Form.Control type="number" id="fx_rates" onChange={onFxRateChangeHandler} placeholder='Enter margin' value={fx_rate} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="country">Country</Form.Label>
                            <Form.Control type="text" id="country" value={country} readOnly />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="default_vendor">Default Vendor</Form.Label>
                            <Form.Select id="default_vendor" name="default_vendor" className="form-control" onChange={onDefaultVendorChangeHandler} value={defaultVendor}>
                                <option value={1}>RELOADLY</option>
                                <option value={2}>DING CONNECT</option>
                                <option value={3}>ZENDIT</option>
                                <option value={4}>PREPAY</option>
                            </Form.Select>
                        </Form.Group>
                        <div className="custom-control custom-switch custom-control-inline mt-2">
                            <Form.Check.Input bsPrefix="custom-control-input" id="customSwitch2" onChange={onStatusChangeHandler} checked={status ? true : false} />
                            <Form.Check.Label bsPrefix="custom-control-label" htmlFor="customSwitch2">Enable</Form.Check.Label>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button className="btn btn-primary loginBtn" onClick={handleClose}>Close</Button> */}
                    {isSubmiting ? <div className="loader-box float-right">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div> : <Button className="btn btn-primary loginBtn" onClick={onSubmitHandler}>Submit</Button>}
                </Modal.Footer>
            </Modal>
            {/* Edit Operator Modal */}

            {/* Bulk Edit Operator Modal */}
            <Modal show={bulkShow}>
                <Modal.Header>
                    <Modal.Title as="h5">Edit Bulk Operators</Modal.Title>
                    <button className="badge bg-danger modal-close-button" onClick={handleBulkClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="margin">Margin (in %)</Form.Label>
                            <Form.Control type="number" id="margin" onChange={onMarginChangeHandler} placeholder='Enter margin' value={margin} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="margin">FX Rate</Form.Label>
                            <Form.Control type="number" id="margin" onChange={onFxRateChangeHandler} placeholder='Enter margin' value={fx_rate} />
                        </Form.Group>
                        <div className="custom-control custom-switch custom-control-inline mt-2">
                            <Form.Check.Input bsPrefix="custom-control-input" id="customSwitch2" onChange={onStatusChangeHandler} checked={status ? true : false} />
                            <Form.Check.Label bsPrefix="custom-control-label" htmlFor="customSwitch2">Enable</Form.Check.Label>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button className="btn btn-primary loginBtn" onClick={handleClose}>Close</Button> */}
                    {isSubmiting ? <div className="loader-box float-right">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div> : <Button className="btn btn-primary loginBtn" onClick={onSubmitHandler}>Submit</Button>}
                </Modal.Footer>
            </Modal>
            {/* Bulk Edit Operator Modal */}

            {/* Filter Modal */}
            <Modal size="lg" show={filterModalShow}>
                <Modal.Header>
                    <Modal.Title as="h5">Filter Operators</Modal.Title>
                    <button className="badge bg-danger modal-close-button" onClick={handleFilterModalClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="id">Id</Form.Label>
                                    <Form.Control id="id" onChange={onFilterChangeHandler} name="id" placeholder='Enter Id' value={filterData.id} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="gatewayId">Vendor</Form.Label>
                                    <Form.Select id="gatewayId" className="form-control" name="gatewayId" onChange={onFilterChangeHandler} value={filterData.gatewayId}>
                                        <option value={''} disabled>Select Vendor</option>
                                        <option value={1}>Reloadly</option>
                                        <option value={2}>Ding Connect</option>
                                        <option value={3}>Zendit</option>
                                        <option value={4}>Prepay</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="country">Country</Form.Label>
                                    <Form.Select id="country" className="form-control" name="country" onChange={onFilterChangeHandler} value={filterData.country}>
                                        <option value={''} disabled>Select Country</option>
                                        {countries.length > 0 ? countries.map((country, i) => {
                                            return (
                                                <option value={country.country} key={i}>{country.country}</option>
                                            )
                                        }) : null}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="originalName">Name</Form.Label>
                                    <Form.Control type="text" id="originalName" placeholder='Enter Name' name="originalName" onChange={onFilterChangeHandler} value={filterData.originalName} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="operatorId">Operator Id</Form.Label>
                                    <Form.Control type="text" id="operatorId" placeholder='Enter Operator Id' name="operatorId" onChange={onFilterChangeHandler} value={filterData.operatorId} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="skuCode">SKU Code</Form.Label>
                                    <Form.Control type="text" id="skuCode" placeholder='Enter SKU Code' name="skuCode" onChange={onFilterChangeHandler} value={filterData.skuCode} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="productId">Product Id</Form.Label>
                                    <Form.Control type="text" id="productId" placeholder='Enter Product Id' name="productId" onChange={onFilterChangeHandler} value={filterData.productId} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="status">Status</Form.Label>
                                    <Form.Select id="status" className="form-control" name="status" onChange={onFilterChangeHandler} value={filterData.status}>
                                        <option value={''} disabled>Select Status</option>
                                        <option value={1}>Active</option>
                                        <option value={0}>Deactive</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="limit">Limit Per Page</Form.Label>
                                    <Form.Select id="limit" className="form-control" name="limit" onChange={onFilterChangeHandler} value={filterData.limit}>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                        <option value={1000}>1000</option>
                                        <option value={5000}>5000</option>
                                        <option value={0}>All</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button className="btn btn-primary loginBtn" onClick={handleClose}>Close</Button> */}
                    {isSubmiting ? <div className="loader-box float-right">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div> : <Button className="btn btn-primary loginBtn" onClick={onFilterSubmitHandler}>Submit</Button>}
                </Modal.Footer>
            </Modal>
            {/* Filter Modal */}

            {/* Upload Modal */}
            <Modal show={uploadModalShow}>
                <Modal.Header>
                    <Modal.Title as="h5">Upload Operator File</Modal.Title>
                    <button className="badge bg-danger modal-close-button" onClick={handleCloseUploadModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {file ?
                        <div>{file.name}</div>
                        :
                        <Form onDrop={handleDrop} onDragOver={handleDragOver}>
                            <Form.Label htmlFor="uploadFile" className={error !== null ? "is-invalid uploadFileSection" : "uploadFileSection"}>
                                {/* <strong>Drop File&nbsp;</strong> or click here */}
                                <Form.Control type="file" id="uploadFile" onChange={changefileHandler} accept=".xlsx, .xls" />
                                <div
                                    className="file-drop-area"
                                    onClick={() => document.getElementById('uploadFile').click()} // Open file dialog on click
                                >
                                    {file ? file.name : 'Drag and drop a file here or click to select'}
                                </div>
                            </Form.Label>
                            <div className="invalid-feedback">{error}</div>
                        </Form>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button className="btn btn-primary loginBtn" onClick={handleClose}>Close</Button> */}
                    {isUploading ? <div className="loader-box float-right">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div> : <Button className="btn btn-primary loginBtn" onClick={fileUploadHandler}>Upload</Button>}
                </Modal.Footer>
            </Modal>
            {/* Upload Modal */}
        </section>
    );
}

export default Commissions;
