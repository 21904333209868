import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Badge, Card, Col, Container, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../store/actions';
import $ from 'jquery';
import Swal from 'sweetalert2';

const Vendors = ({ user }) => {
    const dispatch = useDispatch();
    const [vendors, setVendors] = useState([]);
    const [balance, setBalance] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const dataTableRef = useRef(null);

    useEffect(() => {
        getVendors();
    }, []);

    useEffect(() => {
        // Initialize the DataTable when categories change
        if (!isLoading && vendors.length > 0) {
            if (!$.fn.DataTable.isDataTable(dataTableRef.current)) {
                // If not initialized, initialize the DataTable
                $(dataTableRef.current).DataTable({
                    columns: [
                        { data: 'id', title: 'ID' },
                        { data: 'name', title: 'Name' },
                        { data: 'balance', title: 'Balance' },
                        { data: 'status', title: 'Status' },
                        { data: 'default_vendor', title: 'Default Vendor' },
                        { data: '', title: 'Action' },
                    ],
                    order: [[0, 'asc']],
                });
            }

            $(dataTableRef.current).on('click', '.status-button', function () {
                const vendorId = $(this).data('vendor-id');
                const status = $(this).data('status');
                onChangeStatus(vendorId, status);
            });

            $(dataTableRef.current).on('click', '.default-button', function () {
                const vendorId = $(this).data('vendor-id');
                onDefaultVendorChange(vendorId);
            });
        }
    }, [vendors, isLoading]);

    const onChangeStatus = (vendorId, status) => {
        const token = user.user.user.token;

        const headers = {
            Authorization: `Bearer ${token}`,
        }

        const message = status ? 'Are you sure, you want to deactivate this vendor?' : 'Are you sure, you want to activate this vendor?';
        Swal.fire({
            title: message,
            showCancelButton: true,
            confirmButtonText: "Confirm",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.post(process.env.REACT_APP_API_URL + '/update-vendor-status', { vendorId: vendorId, status: status }, { headers })
                    .then((res) => {
                        Swal.fire("Success!", res.data.message, "success").then(() => {
                            getVendors();
                        });
                    })
                    .catch((err) => {
                        Swal.fire("Ooops!", err.response.data.message, "error");
                    })
            }
        });
    }

    const onDefaultVendorChange = (vendorId) => {
        const token = user.user.user.token;
        const headers = {
            Authorization: `Bearer ${token}`,
        }
        const message = 'Are you sure, you want to set this vendor as default?';
        Swal.fire({
            title: message,
            showCancelButton: true,
            confirmButtonText: "Confirm",
        }).then((result) => {
            Swal.fire({
                title: 'Processing...',
                text: 'Please wait while we process your request,Its taking long time due to huge data',
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.post(process.env.REACT_APP_API_URL + '/update-default-vendor', { vendorId: vendorId }, { headers })
                    .then((res) => {
                        Swal.close();
                        Swal.fire("Success!", res.data.message, "success").then(() => {
                            getVendors();
                        });
                    })
                    .catch((err) => {
                        Swal.fire("Ooops!", err.response.data.message, "error");
                    })
            }
        });
    }

    const getVendors = () => {
        if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
            $(dataTableRef.current).DataTable().destroy(); // Destroy existing DataTable
        }
        setVendors([]);
        setIsLoading(true);

        const token = user.user.user.token;

        const headers = {
            Authorization: `Bearer ${token}`,
        }

        axios.get(process.env.REACT_APP_API_URL + '/get-vendors', { headers })
            .then((response) => {
                setVendors(response.data.data);
                setBalance(response.data.balance);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching users:', error);

                if (error.response.status === 401) {
                    dispatch(logoutUser());
                }

                setIsLoading(false);
            });
    }

    return (
        <section className="resellerProfile">
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-weight-bold">Vendors</h4>
                            </div>
                        </div>
                        <Row>
                            <Col lg="12">
                                <Card className="card-block card-stretch">
                                    <Card.Body className="p-0">
                                        <div className="d-flex justify-content-between align-items-center p-3">
                                            <h5 className="font-weight-bold">Vendors List</h5>
                                        </div>
                                        <div className="table-responsive">
                                            {isLoading ? (
                                                <div className="text-center mb-5">
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (
                                                <table ref={dataTableRef} className="table data-table mb-0">
                                                    <thead className="table-color-heading text-left">
                                                        <tr className="">
                                                            <th scope="col">
                                                                Id
                                                            </th>
                                                            <th scope="col">
                                                                Name
                                                            </th>
                                                            <th scope="col">
                                                                Balance
                                                            </th>
                                                            <th scope="col">
                                                                Status
                                                            </th>
                                                            <th scope="col">
                                                                Default Vendor
                                                            </th>
                                                            <th scope="col">
                                                                Action
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {vendors.map((vendor, index) => {
                                                            return (
                                                                <tr key={index} className="white-space-no-wrap">
                                                                    <td>{vendor.id}</td>
                                                                    <td className="">
                                                                        <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                                            <div className="data-content">
                                                                                <div>
                                                                                    <span className="font-weight-bold">{vendor.name}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>&euro; {balance[vendor.name]}</td>
                                                                    <td>{vendor.status ? 'Active' : 'Deactive'}</td>
                                                                    <td>
                                                                        {vendor.default_vendor ?
                                                                            <Badge pill className="bg-success">Active</Badge>
                                                                            :
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Change Default Vendor</Tooltip>}>
                                                                                <button className="text-info default-button" data-vendor-id={vendor.id}>
                                                                                    <Badge pill className="bg-info">Set as default</Badge>
                                                                                </button>
                                                                            </OverlayTrigger>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>Change Status</Tooltip>}>
                                                                            <button className="text-info status-button" data-vendor-id={vendor.id} data-status={vendor.status ? true : false}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                                                                </svg>
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Vendors;
